<template>
  <el-select
    v-model="cafeteriasModel"
    @change="cafeteriasChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.CAFETERIAS')"
    :filterable="true"
    :multiple="true"
    :disabled="disabled"
    remote
    :remote-method="getCafeterias"
    :loading="loading"
  >
    <el-option
      v-for="cafeteria in cafeteriasList"
      :key="cafeteria.id"
      :value="cafeteria.id"
      :label="`${cafeteria.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { difference } from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "cafeterias-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    cafeterias: {
      type: Array,
      default: () => [],
      description: "Cafeterias",
    },
    organization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterEstablishments: {
      type: Array | String,
      default: null,
      description: "establishments id",
    },
  },

  data() {
    return {
      cafeteriasModel: [...this.cafeterias].map((item) => item.id),
      cafeteriasList: {},
    };
  },

  setup() {},

  created() {
    this.getCafeterias(null, this.cafeterias);
  },

  methods: {
    async getCafeterias(query = null, cafeterias = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(cafeterias ? { ids: cafeterias.map((item) => item.id) } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.organization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.organization,
            },
          };
        }
        if (this.filterEstablishments) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              cafeteria: this.filterCafeterias,
            },
          };
        }

        await this.$store.dispatch("cafeterias/list", params);
        const cafeteriasArr = await this.$store.getters["cafeterias/list"];
        this.cafeteriasList = {};
        cafeteriasArr.forEach((cafeteria) => {
          this.cafeteriasList[cafeteria.id] = cafeteria;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async cafeteriasChanged(cafeterias) {
      this.$emit(
        "cafeteriasChanged",
        [...cafeterias].map((item) => ({
          type: "cafeterias",
          id: item,
        }))
      );
    },
  },

  watch: {
    cafeterias(cafeterias) {
      if (cafeterias && cafeterias.length != 0) {
        const cafeteriasData = cafeterias.map((item) => item.id);
        if (difference(cafeteriasData, this.cafeteriasModel).length !== 0) {
          this.cafeteriasModel = cafeteriasData;
          this.getCafeterias(null, cafeterias);
        }
      } else {
        this.cafeteriasModel = [];
      }
    },
    organization(organization) {
      this.getCafeterias();
    },
    filterEstablishments: {
      handler: function (cafeterias) {
        this.getCafeterias(null, cafeterias);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
